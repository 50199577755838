<script setup>
import { useToast } from 'primevue/usetoast';
import { useVuelidate } from '@vuelidate/core';
import { email, helpers, required } from '@vuelidate/validators';
import { ref, defineExpose, defineEmits } from 'vue';
import BaseService from '@/services/BaseService';
import EnumTipoContato from '@/enums/EnumTipoContato';
import EnumClassificacaoContato from '@/enums/EnumClassificacaoContato';

const contatoSalvo = ref(false);
const contato = ref({});
const novoContato = {
    nome: '',
    email: '',
    contato: '',
    cargo: '',
    tipoContato: EnumTipoContato.CONVOCACAO_EXAME,
    classificacaoContato: EnumClassificacaoContato.INTERNO,
    customerId: null
};

const rules = {
    nome: {
        required: helpers.withMessage('Campo obrigatório', required)
    },
    email: {
        email: helpers.withMessage('E-mail inserido é inválido', email)
    },
    contato: {
        required: helpers.withMessage('Campo obrigatório', required)
    },
    cargo: {
        required: helpers.withMessage('Campo obrigatório', required)
    }
};
const v$ = useVuelidate(rules, contato);

async function validarFormulario() {
    const formularioCorreto = await v$.value.$validate();
    if (formularioCorreto) salvar();
}
async function salvar() {
    try {
        contato.value = await service.save(contato.value);
        contatoSalvo.value = true;
        fechar();
    } catch (error) {
        toast.add({
            severity: 'error',
            detail: 'Erro ao tentar salvar o contato',
            life: 3000
        });
    }
}

const visivel = ref(false);
const toast = useToast();
const service = new BaseService('/customer-contato');
async function abrir(customerId) {
    try {
        const contatos = await service.findById(customerId);
        const contatoConvocacao = contatos.data.find((contato) => {
            return contato.tipoContato === EnumTipoContato.CONVOCACAO_EXAME;
        });
        if (contatoConvocacao) {
            contatoSalvo.value = true;
        }

        contato.value = contatoConvocacao || { ...novoContato, customerId };
        visivel.value = true;
    } catch (error) {
        toast.add({
            severity: 'error',
            detail: 'Erro ao tentar buscar o contato',
            life: 3000
        });
        emit('cancelar');
    }
}
const emit = defineEmits(['cancelar']);
function fechar() {
    visivel.value = false;
}

defineExpose({ abrir });
</script>
<template>
    <Dialog
        v-model:visible="visivel"
        closable
        header="Contato da pessoa responsável pela convocação de periódicos"
        modal
        style="width: 450px"
        @update:visible="fechar()"
    >
        <div class="grid">
            <div class="col-12 md:col-6 flex flex-column">
                <label for="nome">Nome</label>
                <InputText
                    id="nome"
                    v-model.trim="contato.nome"
                    :class="{ 'p-invalid': v$.nome.$error }"
                    autocomplete="off"
                    maxlength="150"
                    required="true"
                />
                <small v-if="v$.nome.$error" class="p-error">
                    {{ v$.nome.$errors[0].$message }}
                </small>
            </div>
            <div class="col-12 md:col-6 flex flex-column">
                <label for="email">E-mail</label>
                <InputText id="email" v-model.trim="contato.email" :class="{ 'p-invalid': v$.email.$error }" />
                <small v-if="v$.email.$error" class="p-error">
                    {{ v$.email.$errors[0].$message }}
                </small>
            </div>
            <div class="col-12 md:col-6 flex flex-column">
                <label for="telefone">Telefone</label>
                <InputMask id="telefone" v-model.trim="contato.contato" :class="{ 'p-invalid': v$.contato.$error }" mask="(99) 99999-9999" />
                <small v-if="v$.contato.$error" class="p-error">
                    {{ v$.contato.$errors[0].$message }}
                </small>
            </div>
            <div class="col-12 md:col-6 flex flex-column">
                <label for="cargo">Cargo</label>
                <InputText id="cargo" v-model.trim="contato.cargo" :class="{ 'p-invalid': v$.cargo.$error }" />
                <small v-if="v$.cargo.$error" class="p-error">
                    {{ v$.cargo.$errors[0].$message }}
                </small>
            </div>
        </div>
        <template #footer>
            <Button label="Cancelar" class="p-button-text" @click="fechar()" />
            <Button label="Salvar" @click="validarFormulario()" />
        </template>
    </Dialog>
</template>
